import jQuery from 'jquery/dist/jquery';
import DataTable from 'datatables.net-dt';

import Swal from 'sweetalert2';
import {German} from "flatpickr/dist/l10n/de.js"

import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import TicketEntity from '../entity';
import TicketFilterEntity from "../../ticket_filter/entity";
import flatpickr from "flatpickr";
import UtilsEntity from "../../../utils/entity";

export default class TicketOverviewList extends DefaultController {
    tableElem: any;
    datatable: any;
    filterModal: any;

    async init() {
        this.entity = "ticket";
        this.tableElem = jQuery('.datatables-users')
        this.createTable();
        await super.init();
    }

    getEntityData(elem: any) {
        return TicketEntity.getEntityData(elem)
    }

    bindListeners() {
        this.filterModal = new bootstrap.Modal((document.querySelector("#newTicketFilterModal") as HTMLElement));
        (document.querySelectorAll("button.ticket_filter") as NodeListOf<HTMLButtonElement>).forEach((ticketFilterButton: any) => {
            ticketFilterButton.addEventListener("click", (e) => {
                e.preventDefault();
                const savedFilters = JSON.parse(ticketFilterButton.getAttribute("data-filter") as string);
                this.datatable.columns().every((col: number) => {
                    const column = this.datatable.column(col);
                    const header = column.header();
                    const filterElem = header.querySelector("select")
                    if (filterElem) {
                        if (savedFilters && savedFilters[`col-${col}`]) {
                            const values = savedFilters[`col-${col}`].split("|")
                            jQuery(filterElem).val(values);
                            jQuery(filterElem).trigger('change');
                            if (col === 6 && values.indexOf("Ja") !== -1) {
                                column.search('^((?!Nein).)*$', true, false).draw()
                            } else if (col === 5 && values.indexOf('Nicht zugewiesen') !== -1) {
                                column.search('', true, false).draw()
                            } else {
                                column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                            }
                        }
                    }
                })
            })
        });

        (document.querySelector("#ticket_save_filter") as HTMLButtonElement).addEventListener("click", (e: any) => {
            e.preventDefault();
            this.filterModal.show();
            let savedFilters = localStorage.getItem("fltools_tickets_filter") as string
            (document.querySelector("#newTicketFilterForm #ticket_filter_filter") as HTMLInputElement).value = savedFilters;
        });
        (document.querySelector("#newTicketFilterForm") as HTMLFormElement).addEventListener("submit", async (e: any) => {
            e.preventDefault();
            const elem = (document.querySelector("#newTicketFilterForm") as HTMLFormElement);
            const entityData = TicketFilterEntity.getEntityData(elem)
            console.log("entityData", entityData)
            await Utils.showLoader()
            const r = await Utils.entity.upsert(entityData, 'ticket_filters')
            if (r && r.status === 200) {
                const newBtn = r.data[0]
                document.location.reload();
                await Utils.hideLoader()
            }
        });

        this.tableElem.delegate(".delete-record", "click", async (e: any) => {
            const confirm = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary me-3',
                    cancelButton: 'btn btn-label-secondary'
                },
                buttonsStyling: false
            })
            if (confirm.value === true) {
                const id = e.currentTarget.getAttribute("data-id");
                const r = await Utils.entity.destroy(id, this.entity);
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('ticket.name')} ${Utils.translate('generic.deleted')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('ticket.name')} ${Utils.translate('generic.messages.not_deleted')}`, `${Utils.translate('generic.error')}`)
                }
                this.datatable.ajax.reload();
            }
        });
    }

    createTable() {
        let queueId = null;
        if (document.querySelector("div[data-queue-id]")) {
            queueId = (document.querySelector("div[data-queue-id]") as HTMLElement).getAttribute("data-queue-id")
        }
        const action = document.body.getAttribute("data-current-action")
        let url = queueId ? `/api/v1/tickets?queue_id=${queueId}` : `/api/v1/tickets?childs=false`
        if (action === "archive") {
            url = `/api/v1/tickets?archive=true&childs=false`
        }
        let initComplete = false
        this.datatable = new DataTable(".datatables-users", {
            initComplete: async () => {
            },
            ajax: {
                url: url,
                dataType: 'json',
                beforeSend: () => {
                    this.tableElem.closest(".card").find(".card-datatable").addClass("d-none")
                    this.tableElem.closest(".card").find(".loading-body").removeClass("d-none")
                },

                complete: () => {
                    if (initComplete) {
                        this.tableElem.closest(".card").find(".loading-body").addClass("d-none")
                        this.tableElem.closest(".card").find(".card-datatable").removeClass("d-none")
                    }
                },
                // @ts-ignore
                data: (params: { length: any; start: any; draw: any; order: any; search: any; columns: any; }) => {
                    return {
                        length: params.length,
                        draw: params.draw,
                        start: params.start,
                        search: params.search ? params.search.value : null,
                        sort: params.order ? params.order[0] : [],
                        columns: params.columns
                    };
                },
                // @ts-ignore
                dataSrc: (data: any, params: any) => {
                    if (data.status === 200) {
                        data["recordsTotal"] = data.meta.total;
                        data["recordsFiltered"] = data.meta.total;
                        data["draw"] = data.meta.draw;
                    }
                    return data.data;
                },
                cache: true
            },
            order: [[0, 'desc']],
            ordering: false,
            serverSide: true,
            pageLength: 50,
            columns: [
                {data: 'number'},
                {data: 'uuid'},
                {data: 'title'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `<a href="/${(<any>window).currentLocale}/ticket/tasks/${full.uuid}">${data}</a>`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.ticket_category ? full.ticket_category.name : "";
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.children && full.children.length > 0) {
                            const doneTickets = full.children.filter((t: { is_done: any; }) => t.is_done);
                            const progress = doneTickets.length / full.children.length * 100;
                            return `<div class="row align-items-center"><div class="col-12 mb-1 text-center">${data}</div>
<div class="col-8">
<div class="progress position-relative"> 
<div class="progress-bar" role="progressbar" style="width: ${progress}%" aria-valuenow="${progress}" aria-valuemin="0" aria-valuemax="100"></div> <small class="justify-content-center d-flex position-absolute w-100" style="font-size: 10px;">${doneTickets.length} / ${full.children.length} Tickets</small> 
</div>

</div><div class="col-2"><button class="btn btn-xs btn-primary row-toggler"><i class="ti ti-caret-down"></i></button></div>
</div> `;
                        } else {
                            return `${data}`;
                        }
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.customer) {
                            return `<a href="/${(<any>window).currentLocale}/customer/customers/${full.customer.uuid}">${full.customer.name}</a>`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.customer_reference;
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.project) {
                            return `${full.project.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 6,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.assignee) {
                            return `${full.assignee.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 7,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str = "";
                        if (full.calendar_appointments.length === 0) {
                            str = "Nein";
                        } else {
                            full.calendar_appointments.forEach((calendar_appointment: any) => {
                                str += `<span style="font-size: 10px"> ${calendar_appointment.from}</span><br />`
                            })
                        }

                        return str;
                    },
                },
                {
                    targets: 8,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.status) {
                            return `${full.status.name}`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 9,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if (full.priority) {
                            return `<span style="background-color:${full.priority.color};width: 100%; font-weight: bold; display: flex; justify-content: center; align-items: center; height: 100%; padding: 4px; text-align: center; color: #FFF;">${full.priority.name}</span>`;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    targets: 10,
                    searchable: false,
                    orderable: false,
                    render: (data: any, type: any, full: any, meta: any) => {
                        if ((<any>window).userPermissions.Ticket.can_update) {
                            return `<div class="d-flex align-items-center justify-content-end">
                                  <a href="/${(<any>window).currentLocale}/ticket/tasks/${data}" class="text-body">
                                    <i class="ti ti-edit ti-sm me-2"></i>
                                  </a>
                                </div>`
                        } else {
                            return ''
                        }
                    },
                }
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                `<"col-6"<"me-3 m-3 d-flex"l>>` +
                '<"col-6"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"B>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: (<any>window).userPermissions.Ticket.can_create ? [
                {
                    text: `<i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">${Utils.translate('generic.add')}</span>`,
                    className: 'dt-button add-new btn btn-primary m-2',
                    action: () => {
                        document.location.href = `/${(window as any).currentLocale}/ticket/tasks/new`
                    }
                }
            ] : []
        });

        setTimeout(() => {
            initComplete = true
            console.log("this.datatable", this.datatable)
            if (action !== "archive") {
                this.datatable.columns().every(async (col: any) => {
                    if (col !== 0 && col !== 10) {
                        const column = this.datatable.column(col);
                        const header = column.header();
                        let filterElem: any = null
                        if (col === 7) {
                            filterElem = document.createElement("input")
                            filterElem.classList.add("form-control")
                            flatpickr(filterElem, {
                                mode: 'range',
                                onChange: (selectedDates: any, dateStr: any, instance: any) => {
                                    let from = new Date(selectedDates[0]);
                                    let to = new Date(selectedDates[1]);
                                    let values = []
                                    if (!to) {
                                        to = new Date(Date.parse("2025-12-31"))
                                    }
                                    for (let d = from; d <= to; d.setDate(d.getDate() + 1)) {
                                        values.push(new Date(d).toLocaleDateString("sv-SE", {
                                            month: "2-digit",
                                            year: "numeric",
                                            day: "2-digit"
                                        }));
                                    }
                                    this.datatable.settings()[0].jqXHR.abort()
                                    column.search(values.join("|").length > 0 ? '' + values.join("&") + '$' : '', true, false).draw();
                                },
                                showMonths: 1
                            });

                        } else if ((col > 2 || col === 1) && col !== 4) {
                            filterElem = document.createElement('select');
                            filterElem.multiple = true;
                            filterElem.classList.add("form-select")
                            header.appendChild(filterElem);
                        } else {
                            filterElem = document.createElement("input")
                            filterElem.classList.add("form-control")
                            let keyupTimeout: string | number | NodeJS.Timeout | null | undefined = null;
                            filterElem.addEventListener('keyup', () => {
                                if (keyupTimeout) {
                                    clearTimeout(keyupTimeout);
                                }
                                keyupTimeout = setTimeout(() => {
                                    //@ts-ignore
                                    const val = jQuery.fn.DataTable.util.escapeRegex(filterElem.value);
                                    let savedFilters = JSON.parse(localStorage.getItem("fltools_tickets_filter") as string)
                                    if (!savedFilters) {
                                        savedFilters = {}
                                    }
                                    savedFilters[`col-${col}`] = val
                                    localStorage.setItem("fltools_tickets_filter", JSON.stringify(savedFilters))
                                    this.datatable.settings()[0].jqXHR.abort()
                                    column.search(val).draw();
                                }, 600);
                            });
                        }
                        if (col !== 6) {
                            filterElem.addEventListener("click", (e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                            })
                        }
                        filterElem.style.marginTop = "5px"
                        header.appendChild(filterElem);

                        const options: any = {}
                        if (col === 1) {
                            options[col] = (await UtilsEntity.getAll("ticket_categories")).data.map((d: any) => d.name);
                        } else if (col === 3) {
                            options[col] = (await UtilsEntity.getAll("customers")).data.map((d: any) => d.name);
                        } else if (col === 5) {
                            options[col] = (await UtilsEntity.getAll("projects")).data.map((d: any) => d.name);
                        } else if (col === 8) {
                            options[col] = (await UtilsEntity.getAll("statuses")).data.map((d: any) => d.name);
                        } else if (col === 6) {
                            options[col] = (await UtilsEntity.getAll("assignees")).data.map((d: any) => d.name);
                        } else if (col === 9) {
                            options[col] = (await UtilsEntity.getAll("priorities")).data.map((d: any) => d.name);
                        }
                        this.datatable.data().unique().sort().each((d: any, j: any) => {
                            if (col === 7) {
                                if (!options[col]) {
                                    options[col] = []
                                }
                                options[col] = options[col].concat(d.calendar_appointments.map(c => c.from_iso));
                            }
                        });
                        Object.keys(options).forEach((key: any) => {
                            const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index);
                            console.log("vvv", v)
                            v.forEach((value: any) => {
                                if (filterElem.add) {
                                    filterElem.add(new Option(value, value));
                                }
                            })
                        })

                        const savedFilters = JSON.parse(localStorage.getItem("fltools_tickets_filter") as string)
                        if (savedFilters && savedFilters[`col-${col}`]) {
                            const values = savedFilters[`col-${col}`].split("|")
                            values.forEach((v: any) => {
                                if (filterElem.nodeName === "SELECT") {
                                    if (filterElem.querySelector(`option[value='${v}']`)) {
                                        filterElem.querySelector(`option[value='${v}']`).setAttribute("selected", "true")
                                    }
                                } else {
                                    filterElem.value = savedFilters[`col-${col}`].replaceAll("\\.", ".").replaceAll("\\+", "+")
                                }
                            })
                            const ev = new Event(col === 1 ? "keyup" : "change")
                            filterElem.dispatchEvent(ev)
                            this.datatable.settings()[0].jqXHR.abort()
                            column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                        }
                        console.log(filterElem.nodeName)
                        if (filterElem.nodeName === "SELECT") {
                            jQuery(filterElem).select2().on('select2:select select2:unselect', (e: any) => {
                                //@ts-ignore
                                const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {
                                    return jQuery.fn.DataTable.util.escapeRegex(e.value)
                                })
                                console.log(values)
                                let savedFilters = JSON.parse(localStorage.getItem("fltools_tickets_filter") as string)
                                if (!savedFilters) {
                                    savedFilters = {}
                                }
                                savedFilters[`col-${col}`] = values.join("|")
                                localStorage.setItem("fltools_tickets_filter", JSON.stringify(savedFilters))
                                this.datatable.settings()[0].jqXHR.abort()
                                column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                            });
                        }
                    }
                });
            }
            console.log("tt", this.tableElem)
            const paginationSelect = this.tableElem.closest(".card").find("div.dataTables_length");
            if (paginationSelect) {
                paginationSelect.parent().append(
                    `<input type="text" style="margin-left: 5px;max-width:220px;" class="form-control form-control-sm flatpickr-input" placeholder="YYYY-MM-DD bis YYYY-MM-DD" id="ticket_filter_range" readonly="readonly">`)

                let config = {
                    mode: 'range',
                    onChange: async (selectedDates: any, dateStr: any, instance: any) => {
                        let from = selectedDates[0];
                        let to = selectedDates[1];
                        console.log(from, to)
                        if (!selectedDates[1]) {
                            to = new Date();
                            to.setYear(2040)

                        }
                        console.log(from, to)
                        let currentUrl = this.datatable.ajax.url();
                        if (currentUrl.indexOf("?") !== -1) {
                            currentUrl = currentUrl.split("?")[0]
                        }
                        let newUrl = `${currentUrl}?from=${btoa(from)}&to=${btoa(to)}`
                        this.tableElem.addClass("d-none")
                        await this.datatable.ajax.url(newUrl).load(() => {
                            this.tableElem.removeClass("d-none")
                        })
                    },
                    showMonths: 1
                }
                if ((window as any).currentLocale === 'de') {
                    // @ts-ignore
                    config["locale"] = German;
                }
                document.querySelector("#ticket_filter_range")?.flatpickr(config);
            }
        }, 0)
        this.datatable.on('click', 'td .row-toggler', (e) => {
            const target = e.target;
            let tr = target.closest('tr');
            let row = this.datatable.row(tr);

            if (row.child.isShown()) {
                target.querySelector("i.ti")?.classList.add("ti-caret-down");
                target.querySelector("i.ti")?.classList.remove("ti-caret-up");
                row.child.hide();
            } else {
                target.querySelector("i.ti")?.classList.remove("ti-caret-down");
                target.querySelector("i.ti")?.classList.add("ti-caret-up");
                row.child(this.generateSubTickets(row.data())).show();
            }
        });
    }

    generateSubTickets(data: any) {
        let html = `<table class="table table-striped"><!--<thead><tr>
        <th>#</th>
        <th>Status</th>
        </tr></thead>!--><tbody>`;
        console.log("DATA: ", data)
        data.children.forEach((child: any) => {
            html += `<tr>
<td>${child.number}</td>
<td><a href="/${(<any>window).currentLocale}/ticket/tasks/${child.uuid}">${child.title}</a></td>
<td>${child.status.name}</td>
<td>${child.assignee ? child.assignee.name : ""}</td> 
</tr>`
        })
        html += `</tbody></table>`;
        return html;
    }
}